import { render, staticRenderFns } from "./importCommunityIntegratedInfo.vue?vue&type=template&id=b3558412&scoped=true"
import script from "./importCommunityIntegratedInfo.vue?vue&type=script&lang=js"
export * from "./importCommunityIntegratedInfo.vue?vue&type=script&lang=js"
import style0 from "./importCommunityIntegratedInfo.vue?vue&type=style&index=0&id=b3558412&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3558412",
  null
  
)

export default component.exports